<template>
    <div>
        <Breadcrumb titulo="DISCO-VIRTUAL" :items="itensBreadcrumb" backgroundColor="#5e6d51" />
        <div class="disco-virtual-page m-3">

            <!--<h5 class="disco-virtual-titulo" style="background-color: #5e6d51;">DISCO VIRTUAL</h5>-->



            <b-container fluid class="disco-virtual-container p-4">
                <b-row class="w-100 m-auto">
                    <b-col class="disco-particao-upload-arquivos">

                        <input-file placeholder="Fazer upload de arquivo"
                                    ref="inputArquivo"
                                    :tiposArquivoAceitos="tiposArquivosAceitos"
                                    @change="uploadArquivo" />

                    </b-col>

                    <div class="mx-4"></div>

                    <b-col class="disco-particao-pesquisa-arquivos">

                        <div class="w-75">
                            <input-pesquisa @filtrar="nomeArquivoPesquisado = $event" />
                        </div>

                    </b-col>

                </b-row>

            </b-container>

            <b-container fluid class="disco-virtual-container-arquivos p-4">

                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.DiscoVirtual variant="info">Disco Virtual</b-button>
                </b-card-header>
                <b-collapse id="DiscoVirtual" accordion="my-accordion" role="tabpanel">

                    <b-overlay :show="loading" rounded="sm" no-wrap></b-overlay>

                    <b-container fluid class="disco-virtual-container pt-4 pl-4 pr-4">
                        <b-row class="w-100 m-auto">
                            <b-col class="disco-particao-upload-arquivos">
                                <b-button pill variant="outline-danger" @click="deleteAll">Deletar Todos os Arquivos</b-button>
                            </b-col>

                        </b-row>
                    </b-container>

                    <b-card-body>

                        <b-row cols="5" class="disco-virtual-lista-cards mt-2">

                            <b-col class="my-3"
                                   v-for="arquivo in arquivos.discoVirtual"
                                   :key="arquivo">

                                <disco-virtual-card :urlArquivo="arquivo"
                                                    :isDiscoRaiz="true"
                                                    @deletar-arquivo="deletarArquivo" />

                            </b-col>

                        </b-row>

                    </b-card-body>

                </b-collapse>

                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.Logos variant="info">Logos</b-button>
                </b-card-header>
                <b-collapse id="Logos" accordion="my-accordion" role="tabpanel">

                    <b-overlay :show="loading" rounded="sm" no-wrap></b-overlay>

                    <b-card-body>

                        <b-row cols="5" class="disco-virtual-lista-cards mt-2">

                            <b-col class="my-3"
                                   v-for="arquivo in arquivos.logos"
                                   :key="arquivo">

                                <disco-virtual-card :urlArquivo="arquivo"
                                                    :isLogos="true"
                                                    @editar-arquivo="editLogo" />

                            </b-col>

                        </b-row>

                    </b-card-body>

                </b-collapse>

            </b-container>

        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import InputFile from '@/components/inputs/InputFile.vue'
    import InputPesquisa from '@/components/inputs/InputPesquisa.vue'
    import DiscoVirtualCard from '@/components/disco-virtual/DiscoVirtualCard.vue'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'

    export default {
        name: 'DiscoVirtualPage',
        components: {
            InputFile,
            InputPesquisa,
            DiscoVirtualCard,
            Breadcrumb
        },

        data() {
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-search',
                        texto: 'Disco Virtual',
                        link: ''
                    },
                ],
                tiposArquivosAceitos: '.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .csv',
                nomeArquivoPesquisado: '',
                idPesquisa: 0
            }
        },

        computed: {
            ...mapGetters({
                loading: 'discoVirtual/arquivosDiscoVirtualLoading'
            }),
            arquivos() {
                let lista = this.$store.getters['discoVirtual/arquivosDiscoVirtual']

                if (lista) {
                    if (this.nomeArquivoPesquisado) {
                        let listaPesquisa = {
                            discoVirtual: lista.discoVirtual.filter(nome => nome.toLowerCase().includes(this.nomeArquivoPesquisado.toLowerCase())),
                            logos: lista.logos.filter(nome => nome.toLowerCase().includes(this.nomeArquivoPesquisado.toLowerCase()))
                        }
                        return listaPesquisa
                    }
                    return lista
                }
                return []

            },
        },

        methods: {
            buscaArquivos() {
                this.$store.dispatch('discoVirtual/buscaArquivosDiscoVirtual');
            },
            uploadArquivo(event) {
                if (!this.validarTipoExtensaoAceito(event[0].type)) {
                    alert('Tipo de arquivo não aceito')
                    return
                }

                let formData = new FormData();
                formData.append('file', event[0])

                this.$store.dispatch('discoVirtual/uploadArquivoDiscoVirtual', formData)
                this.$refs.inputArquivo.limparArquivo();
            },
            validarTipoExtensaoAceito(extensao) {
                let extensaoFormatada = extensao.split('/').pop()
                return extensaoFormatada ? this.tiposArquivosAceitos.includes(extensaoFormatada)
                    : false
            },
            deleteAll() {
                this.$store.dispatch('discoVirtual/deleteAll')
            },
            deletarArquivo(pathImg) {
                this.$store.dispatch('discoVirtual/deletarArquivoDiscoVirtual', pathImg)
            },
            editLogo(file, nomeFile) {
                var objFile = {
                    file: file,
                    nomeFile: nomeFile
                }

                this.$store.dispatch('discoVirtual/editarArquivosDiscoVirtual', objFile)

                window.location.reload()
            }
        },

        mounted() {
            $("#caixa_carregamento").show()
            this.buscaArquivos()
            setTimeout(() => { $("#caixa_carregamento").hide() }, 2000)
        }
    }

</script>

<style scoped>
    .disco-virtual-titulo {
        color: var(--cinza-5);
    }

    .disco-virtual-container {
        width: 100%;
        background-color: var(--cinza-3);
        margin-bottom: 25px;
    }

    .disco-virtual-container-arquivos {
        width: 100%;
        background-color: var(--cinza-3);
        margin-bottom: 75px;
    }

    .disco-particao-upload-arquivos, .disco-particao-pesquisa-arquivos {
        background-color: white;
        margin: 0 auto;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .disco-virtual-card-arquivo {
        background-color: white;
        min-width: 60px;
        min-height: 300px;
    }
</style>